<template>
  <div class="foot_main">
    <div class="foot_banner" v-if="isBanner">
      <div>7*24小时</div>
      <p>顶级客服团队为您提供全天候的客户服务</p>
      <div class="btn" @click="download">立即下载</div>
    </div>
    <div class="apps">
      <p class="title">下载</p>
      <div class="context" @click="download">
        <!-- <p><img src="@/assets/img/pc/home/foot_nav03.png">Windows</p>
				<p><img src="@/assets/img/pc/home/foot_nav04.png">Linux</p> -->
        <div class="context_item">
          <div>
            <img v-lazy="require('@/assets/img/pc/home/foot_nav_win.png')" />
          </div>
          <p>Windows</p>
        </div>
        <div class="context_item">
          <div>
            <img v-lazy="require('@/assets/img/pc/home/foot_nav_linux.png')" />
          </div>
          <p>Linux</p>
        </div>
      </div>
    </div>
    <div class="introduce">
      <div v-for="item in introduceList" :key="item.id">
        <p v-for="item1 in item.name" :key="item1.text" @click="changeUrl(item1)">
          {{ item1.text }}
        </p>
      </div>
    </div>
    <div class="foot_address">
      <p>版权所有 Copyright © 2019 湖南优享云通信技术有限公司 地址：长沙市天心区天经国际智能产业园1栋1201室</p>
      <p><a href="https://beian.miit.gov.cn" target="_blank">湘ICP备19011646号-1</a></p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isBanner: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {}
  },
  methods: {
    download() {
      let size = window.getComputedStyle(document.getElementsByTagName('html')[0]).fontSize.substring(0, 2)
      size = (5.106382 + 45.957446) * parseInt(size) - 45
      if (this.$route.path != '/m/download') {
        this.$router.push('/m/download')
        this.$nextTick(() => {
          document.documentElement.scrollTop = size
        })
      } else {
        document.documentElement.scrollTop = size
        return
      }
    },
    changeUrl(item) {
      if (item.path) {
        this.$router.push('/m' + item.path)
      }
    }
  },
  computed: {
    introduceList() {
      return this.$store.state.introduceList
    }
  }
}
</script>

<style lang="scss" scoped>
.foot_main {
  width: 31.914893rem;
  max-width: 90%;
  margin: 0 auto;
  overflow: hidden;
  .foot_banner {
    text-align: center;
    overflow: hidden;
    color: white;
    width: 29.361702rem;
    max-width: 90%;
    height: 11.914893rem;
    margin: 1.276595rem auto 0;
    background-image: url('~@/assets/img/common/foot_banner.jpg');
    background-size: cover;
    border-radius: 0.425531rem;
    div {
      font-size: 1.361702rem;
      margin: 1.702127rem 0 0 0;
    }
    p {
      font-size: 1.106382rem;
      margin: 1.276595rem 0;
    }
    .btn {
      width: 8.510638rem;
      height: 2.553191rem;
      border: none;
      border-radius: 1.276595rem;
      background-color: white;
      margin: 0 auto;
      color: #7d6bef;
      font-size: 1.191489rem;
      line-height: 2.553191rem;
    }
  }
  .apps {
    width: 29.361702rem;
    margin: 2.553191rem auto 0;
    .title {
      font-size: 1.361702rem;
      color: #2e2661;
    }
    .context {
      width: 26.80851rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      .context_item {
        text-align: center;
        div {
          width: 4.255319rem;
          height: 4.255319rem;
          background: #7d6bef;
          border-radius: 50%;
          margin: 0 auto;
          position: relative;
          img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 50%;
          }
        }
      }
      p {
        color: #2e2661;
        font-size: 1.191489rem;
      }
    }
  }
  .introduce {
    width: 29.361702rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    div {
      p {
        text-align: center;
        font-size: 1.021276rem;
        color: #6359a0;
        &:first-child {
          font-size: 1.276595rem;
          color: #2e2661;
        }
      }
    }
    border-bottom: 0.042553rem solid #f2f2fd;
    padding-bottom: 2.553191rem;
  }
  .foot_address {
    width: 26.80851rem;
    margin: 1.702127rem auto;
    font-size: 1.021276rem;
    color: #6359a0;
    text-align: center;
    p {
      margin: 0;
    }
    a {
      cursor: pointer;
    }
  }
}
</style>
