<template>
  <div class="home">
    <div class="swiper">
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide>
          <div class="item banner1">
            <h2>促进和支持网络安全</h2>
            <div>维护健康绿色的网络环境</div>
            <div>保护您的网络财产安全</div>
            <button class="btn" @click="trial">免费试用</button>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item banner2">
            <h2>有线无线网络认证平台</h2>
            <div>通过手机验证码等多种方式登录优享云APP</div>
            <div>助力用户快速连接WiFi</div>
            <button class="btn" @click="trial">免费试用</button>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <div class="context">
      <div class="why">
        <h2>为什么要选择优享云？</h2>
        <swiper ref="mySwiper" :options="swiperOptions">
          <swiper-slide class="item" v-for="item in whySwiperList" :key="item.h3">
            <h3>{{ item.h3 }}</h3>
            <p>{{ item.text }}</p>
            <div class="img">
              <img :src="item.image" />
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
      <div class="process">
        <h2>接入流程</h2>
        <div class="timelinebox">
          <div class="line"></div>
          <TimeLine :data="item" v-for="(item, index) in processTimeList" :key="index"></TimeLine>
        </div>
        <div class="btn" @click="trial">立即咨询</div>
        <div class="telephone">
          <img v-lazy="require('@/assets/logo.png')" alt="" />
          <p @click="tel(18153886184)">营销一部：181-5388-6184 (李经理)</p>
          <p @click="tel(15010366164)">营销二部：150-1036-6164 (彭经理)</p>
          <p @click="tel(18932451909)">技术支持：189-3245-1909 (李经理)</p>
        </div>
      </div>
      <div class="case">
        <h2>近期案例</h2>
        <swiper ref="mySwiper2" :options="swiperOptions" v-if="caseSwiperList.length">
          <swiper-slide class="item" v-for="(item, index) in caseSwiperList" :key="index">
            <!-- <div
              :style="{ backgroundImage: 'url(' + item.mainImg + ')' }"
            ></div> -->
            <img :src="generaCompletePath(item.mainImg)" />
            <h3>{{ item.caseName }}</h3>
            <!-- <div class="mhomehtml" v-html="item.content"></div> -->
            <div class="mhomehtml">{{ handleTetx(item.contentText) }}</div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>

      <div class="partner">
        <h2>合作伙伴</h2>
        <div class="partner_context">
          <div v-for="(item, index) in partnerImgList" :key="index">
            <img v-lazy="item.image" />
          </div>
        </div>
      </div>
      <FootMain></FootMain>
    </div>
  </div>
</template>
<script type="text/javascript">
import TimeLine from '@/components/m/timeline/index.vue'
import FootMain from '@/components/m/foot_main/index.vue'
import { BaseURL } from '@/utils/request.js'

export default {
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        loop: true,
        observer: true,
        loop: true,
        autoplay: {
          delay: 5000000, //自动切换的时间间隔，单位ms
          disableOnInteraction: false //用户操作swiper之后，是否禁止autoplay
        }
      },
      whySwiperList: [
        {
          h3: '01 多种认证方式',
          text: '手机短信认证，微信公众号认证，钉钉账号认证，账号密码认证（支持关联LDAP、AD域，API接口认证），员工授权认证，二维码识别认证，其他第三方数据源校验完成认证。',
          image: require('@/assets/img/m/home/why_swiper01.jpg')
        },
        {
          h3: '02 兼容主流品牌',
          text: '兼容主流有线无线网络设备，如华为、H3C、锐捷、Cisco、Aruba、RUCKUS。多种品牌设备共存于一个管理系统，去繁存简、兼容并包。',
          image: require('@/assets/img/m/home/why_swiper02.jpg')
        },
        {
          h3: '03 配置简单',
          text: '所有配置部署过程均采用向导式配置方式，可视化的认证页面定制， 无须专业的网络管理员也可轻松完成平台部署配置工作，并提供视频资料以及文档资料满足更复杂的开发的配置任务。',
          image: require('@/assets/img/m/home/why_swiper03.jpg')
        },
        {
          h3: '04 旁挂接入',
          text: '旁挂接入，不影响现有网络拓扑结构。最小化成本实现认证系统接入。轻松实现有线无线统一准入控制，为企业上网安全保驾护航。',
          image: require('@/assets/img/m/home/why_swiper04.jpg')
        }
      ],
      processTimeList: [
        {
          num: require('@/assets/img/m/home/process_num01.png'),
          image: require('@/assets/img/m/home/process_num1.png'),
          text: '提供现场拓扑结构；'
        },
        {
          num: require('@/assets/img/m/home/process_num02.png'),
          image: require('@/assets/img/m/home/process_num2.png'),
          text: '根据环境拟定部署方案；'
        },
        {
          num: require('@/assets/img/m/home/process_num03.png'),
          image: require('@/assets/img/m/home/process_num3.png'),
          text: '搭建现场测试环境；'
        },
        {
          num: require('@/assets/img/m/home/process_num04.png'),
          image: require('@/assets/img/m/home/process_num4.png'),
          text: '客户确认签订合同,预付定金；'
        },
        {
          num: require('@/assets/img/m/home/process_num05.png'),
          image: require('@/assets/img/m/home/process_num5.png'),
          text: '开发定制需求完成部署；'
        },
        {
          num: require('@/assets/img/m/home/process_num06.png'),
          image: require('@/assets/img/m/home/process_num6.png'),
          text: '客户完成验收,支付尾款。'
        }
      ],
      /* ? */

      partnerImgList: [
        { image: require('@/assets/img/pc/home/partner_item01.png') },
        { image: require('@/assets/img/pc/home/partner_item02.png') },
        { image: require('@/assets/img/pc/home/partner_item03.png') },
        { image: require('@/assets/img/pc/home/partner_item04.png') },
        { image: require('@/assets/img/pc/home/partner_item05.png') },
        { image: require('@/assets/img/pc/home/partner_item06.png') },
        { image: require('@/assets/img/pc/home/partner_item07.png') },
        { image: require('@/assets/img/pc/home/partner_item08.png') },
        { image: require('@/assets/img/pc/home/partner_item09.png') },
        { image: require('@/assets/img/pc/home/partner_item10.png') },
        { image: require('@/assets/img/pc/home/partner_item11.png') },
        { image: require('@/assets/img/pc/home/partner_item12.png') }
      ]
    }
  },
  components: {
    TimeLine,
    FootMain
  },
  methods: {
    tel(tel) {
      window.location.href = 'tel://' + tel
    },
    trial() {
      this.$router.push('trial')
    },
    generaCompletePath(img) {
      if (img) return `${BaseURL}static${img}`
    },
    handleTetx(text) {
      let height = text.length
      if (height > 80) {
        let newText = text.slice(0, 80) + '...'
        return newText
      }
      return text
    }
  },
  computed: {
    caseSwiperList() {
      return this.$store.state.casesList
    }
  },
  created() {
    this.$store.dispatch('getCasesList')
  }
}
</script>
<style lang="scss" scoped>
@import '@/style/m/home/index.scss';
/deep/ .swiper-pagination-bullet-active {
  height: 0.425531rem;
  border-radius: 0.212765rem;
  width: 1rem !important;
  background-color: #fff !important;
  transition: all 0.3s;
  opacity: 1 !important;
}
/deep/ .swiper-pagination-bullet {
  width: 0.5rem;
  height: 0.425531rem;
  border-radius: 0.212765rem;
  background-color: #ccc;
  opacity: 0.5;
}
/deep/ .swiper-container {
  border-radius: 0.851063rem;
  box-shadow: 0px 0px 5px 3px #f3f2fc;
}
</style>
<style>
.mhomehtml {
  margin: 0 1.276595rem;
  color: #666;
  font-size: 1.191489rem;
  line-height: 2.127659rem;
}
</style>
