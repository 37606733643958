<template>
	<div class="timeline">
		<div class="imgs">
			<div><img v-lazy="data.num"></div>
			<div>	<img v-lazy="data.image"></div>
		</div>
		<p>{{data.text}}</p>
	</div>
</template>

<script>
	export default{
		data() {
			return{}
		},
		props:{
			data:{
				default:[]
			}
		}
	}
</script>

<style lang="scss" scoped>
	
	.timeline{
		
		width: 7.659574rem;
		height: 10.212765rem;
		.imgs{
			width: 5.531914rem;
			height: 5.531914rem;
			margin: 0 auto;
			border-radius: 10%;
			overflow: hidden;
			box-sizing: border-box;
			border: 0.042553rem solid #fff;
			div{
				text-align: center;
				display: flex;
				justify-content: center;
				align-items: center;
				&:first-child{
					height: 1.914893rem;
					background-color: white;
					width: 100%;
					img{
						width: 2.553191rem;
						height: 1.063829rem;
					}
					
				}
				&:last-child{
					height: 3.617021rem;
					background-color: #2c2092;
					
					img{
						width: 2.382978rem;
						height: 1.914893rem;
					}
				}
			}
		}
		p{
			margin: 1.276595rem 0 0  0 ;
			padding: 0;
			// font-size: 1.191489rem;
			font-size: 1.063829rem;
		}
	}
</style>
